import i18n from "i18next";
import HttpApi from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

var language = localStorage.getItem("language");

if (language == null || language == undefined) {
  var ln = window.navigator.language || navigator.browserLanguage;
  ln = ln.slice(0, 2);
  if (ln == "es" || ln == "en") {
    language = ln;
    localStorage.setItem("language", language);
  } else {
    language = "es";
    localStorage.setItem("language", language);
  }
}

i18n
  .use(initReactI18next)
  .use(HttpApi)
  .init({
    initImmediate: false,
    lng: language,
    backend: {
      /* translation file path */
      loadPath: "/assets/i18n/{{ns}}/{{lng}}.json",
      allowMultiLoading: true,
    },
    preload: ["es"],
    fallbackLng: language,
    /* can have multiple namespace, in case you want to divide a huge translation into smaller pieces and load them on demand */
    ns: ["translations"],
    defaultNS: "translations",
    keySeparator: false,
    returnObjects: false,
    interpolation: {
      escapeValue: false,
      formatSeparator: ",",
    },
    react: {
      wait: true,
      useSuspense: true,
    },
  });

export default i18n;
