import {
  LOGIN_USER,
  LOGIN_FAILED,
  GET_USER,
  LOGOUT_USER,
  LOGIN_REFRESH,
} from "../../Store/actions";

export default (state, action) => {
  console.log(action);

  switch (action.type) {
    case LOGIN_USER:
      console.log("action.payload", action.payload);
      let { id_token, access_token, refresh_token } =
        action.payload.data.user.cognito;
      let { profilesList } = action.payload.data.user.dynamo;

      localStorage.setItem("bearer_token", id_token);
      localStorage.setItem("access_token", access_token);
      localStorage.setItem("refresh_token", refresh_token);
      localStorage.setItem("username", action.payload.user);
      localStorage.setItem("session", true);
      localStorage.setItem(
        "profile",
        JSON.stringify(action.payload.data.user.dynamo)
      );

      return {
        ...state,
        loggedIn: true,
        profileIn: profilesList[0],
        message: null,
      };

    case LOGIN_REFRESH:
      console.log("action.payload", action.payload);

      localStorage.setItem(
        "bearer_token",
        action.payload.data.user.cognito.id_token
      );
      localStorage.setItem(
        "access_token",
        action.payload.data.user.cognito.access_token
      );
      localStorage.setItem("username", action.payload.user);
      localStorage.setItem("session", true);
      localStorage.setItem(
        "profile",
        JSON.stringify(action.payload.data.user.dynamo)
      );

      return {
        ...state,
        loggedIn: true,
        message: null,
      };

    case LOGIN_FAILED:
      const message = action.payload ? false : true;

      localStorage.removeItem("bearer_token");
      localStorage.removeItem("access_token");
      localStorage.removeItem("refresh_token");
      localStorage.removeItem("username");
      localStorage.removeItem("session");
      localStorage.removeItem("profile");

      return {
        ...state,
        loggedIn: false,
        message: message,
      };

    case GET_USER:
      localStorage.setItem("profile", JSON.stringify(action.payload.user_data));
      return {
        ...state,
        loggedIn: true,
        message: null,
      };
    case LOGOUT_USER:
      localStorage.removeItem("bearer_token");
      localStorage.removeItem("access_token");
      localStorage.removeItem("refresh_token");
      localStorage.removeItem("username");
      localStorage.removeItem("session");
      localStorage.removeItem("profile");
      return {
        ...state,
        loggedIn: false,
        message: null,
      };
    default:
      return state;
  }
};
