import React from "react";

const Signin = React.lazy(() =>
  import("./Components/Authentication/SignIn/SignIn")
);
const RememberPasword = React.lazy(() =>
  import("./Components/Authentication/RememberPasword/RememberPasword")
);
const ActiveAccount = React.lazy(() =>
  import("./Components/Authentication/ActiveAccount/ActiveAccount")
);
const ResendCode = React.lazy(() =>
  import("./Components/Authentication/ResendCode/ResendCode")
);

const route = [
  { path: "/auth/signin", exact: true, name: "Signin", component: Signin },
  {
    path: "/auth/rememberpassword",
    exact: true,
    name: "Rememberpassword",
    component: RememberPasword,
  },
  {
    path: "/auth/active/account",
    exact: true,
    name: "Account",
    component: ActiveAccount,
  },
  {
    path: "/auth/active/code",
    exact: true,
    name: "Resendcode",
    component: ResendCode,
  },
];

export default route;
