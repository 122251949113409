/** UI actions */
export const COLLAPSE_MENU = "COLLAPSE_MENU";
export const COLLAPSE_TOGGLE = "COLLAPSE_TOGGLE";
export const FULL_SCREEN = "FULL_SCREEN";
export const FULL_SCREEN_EXIT = "FULL_SCREEN_EXIT";
export const CHANGE_LAYOUT = "CHANGE_LAYOUT";
export const NAV_CONTENT_LEAVE = "NAV_CONTENT_LEAVE";
export const NAV_COLLAPSE_LEAVE = "NAV_COLLAPSE_LEAVE";
/** Request actions */
export const GET_REQUEST = "GET_REQUEST";
export const POST_REQUEST = "POST_REQUEST";
export const PUT_REQUEST = "PUT_REQUEST";
export const DELETE_REQUEST = "DELETE_REQUEST";
export const REQUEST_FAILED = "REQUEST_FAILED";
/** Auth actions */
export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_REFRESH = "LOGIN_REFRESH";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const GET_USER = "GET_USER";
export const LOGOUT_USER = "LOGOUT_USER";
/** Alert actions */
export const SHOW_ALERT = "SHOW_ALERT";
export const HIDE_ALERT = "HIDE_ALERT";
/** Loading actions */
export const SHOW_LOADING = "SHOW_LOADING";
export const HIDE_LOADING = "HIDE_LOADING";
/** User actions */
export const CREATE_USER = "CREATE_USER";
export const CREATE_USER_FAILED = "CREATE_USER_FAILED";
export const LIST_USERS = "LIST_USERS";
export const GET_USER_DETAIL = "GET_USER_DETAIL";
/** Machine actions */
export const CREATE_MACHINE = "CREATE_MACHINE";
export const CREATE_MACHINE_FAILED = "CREATE_MACHINE_FAILED";
export const LIST_MACHINES = "LIST_MACHINES";
export const GET_MACHINE_DETAIL = "GET_MACHINE_DETAIL";
