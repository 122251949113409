import React, { useReducer } from "react";
import AuthContext from "./AuthContext";
import AuthReducer from "./AuthReducer";

import {
  LOGIN_USER,
  LOGIN_FAILED,
  GET_USER,
  LOGOUT_USER,
  LOGIN_REFRESH,
} from "../../Store/actions";
import axiosClient from "../../Config/Axios";
import AuthToken from "../../Config/AuthToken";
import { trackPromise } from "react-promise-tracker";

const AuthState = (props) => {
  const initialState = {
    token: localStorage.getItem("accesToken"),
    loggedIn: false,
    user: null,
    message: null,
  };

  const [state, dispatch] = useReducer(AuthReducer, initialState);

  // Login user on API
  const userLogin = async (data) => {
    const { username } = data;
    try {
      const response = await trackPromise(
        axiosClient.post("/users/authenticate", data, {
          params: { function: "login" },
        })
      );
      dispatch({
        type: LOGIN_USER,
        payload: { data: response.data, user: username },
      });
      getLoggedUser();
    } catch (error) {
      //console.log(error);
      dispatch({
        type: LOGIN_FAILED,
      });
    }
  };

  const userProfile = async () => {
    try {
      getLoggedUser();
      const response = await trackPromise(
        axiosClient.post("users/data", {}, { params: { function: "getUser" } })
      );
      const { data } = response;
      dispatch({ type: GET_USER, payload: data });
    } catch (error) {
      //console.log(error);
      dispatch({
        type: LOGIN_FAILED,
      });
    }
  };

  const refreshLogin = async () => {
    try {
      getLoggedUser();
      let username = localStorage.getItem("username");
      let refreshToken = localStorage.getItem("refresh_token");
      if (username != null && refreshToken != null) {
        const data = {
          refresh_token: refreshToken,
          username: username,
        };
        const response = await trackPromise(
          axiosClient.post("/users/authenticate", data, {
            params: { function: "login" },
          })
        );
        dispatch({
          type: LOGIN_REFRESH,
          payload: { data: response.data, user: data.username },
        });
      } else {
        console.log("No user data saved");
      }
    } catch (error) {
      dispatch({
        type: LOGIN_FAILED,
        payload: true,
      });
    }
  };

  const userLogout = async (data) => {
    dispatch({
      type: LOGOUT_USER,
    });
  };

  // Get data of current user
  const getLoggedUser = async () => {
    const bearer_token = localStorage.getItem("bearer_token");
    const access_token = localStorage.getItem("access_token");
    if (bearer_token) {
      // Set header tokens
      AuthToken(bearer_token, access_token);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        token: state.token,
        loggedIn: state.loggedIn,
        profile: JSON.parse(localStorage.getItem("profile")),
        message: state.message,
        userLogin,
        userProfile,
        refreshLogin,
        getLoggedUser,
        userLogout,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthState;
