import axiosClient from "./Axios";

const AuthToken = (bearer_token, access_token) => {
  if (bearer_token) {
    axiosClient.defaults.headers.common["Authorization"] =
      "Bearer " + bearer_token;
    axiosClient.defaults.headers.common["access_token"] = access_token;
  } else {
    delete axiosClient.defaults.headers.common["Authorization"];
    delete axiosClient.defaults.headers.common["access_token"];
  }
};
export default AuthToken;
