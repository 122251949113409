import axios from "axios";

var axiosClientFiles = axios.create({
  baseURL: "https://cors-anywhere.herokuapp.com/",
  headers: {
    "content-type": "multipart/form-data",
  },
});

export default axiosClientFiles;
