import React from "react";
import Spinner from "react-bootstrap/Spinner";
import styled from "@emotion/styled";
import logo from "../../../Assets/images/logo.png";

const ContenedorLoading = styled.div`
  width: 100%;
  height: 100%;
  display: block;
  position: fixed;
  align-items: center;
  justify-content: center;
  background-color: #fff;
`;

const TrackLoading = styled.div`
  display: block;
  margin: 0 auto;
  padding: 3rem;
  border-radius: 0.5rem;
  background-color: #fff;
  max-width: 300px;
  margin-top: 10rem;
  text-align: center;
`;

const loader = () => {
  return (
    <ContenedorLoading>
      <TrackLoading>
        <img style={{ width: "300px" }} src={logo} />
      </TrackLoading>
    </ContenedorLoading>
  );
};

export default loader;
