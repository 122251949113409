import React, { useReducer, useContext } from "react";
import RqContext from "./RqContext";
import RqReducer from "./RqReducer";

import {
  GET_REQUEST,
  POST_REQUEST,
  PUT_REQUEST,
  DELETE_REQUEST,
  REQUEST_FAILED,
} from "../../Store/actions";
import axiosClient from "../../Config/Axios";
import authToken from "../../Config/AuthToken";
import { trackPromise } from "react-promise-tracker";
import { useToasts } from "react-toast-notifications";
import AuthContext from "../Auth/AuthContext";
import i18n from "../../i18n";
import axiosClientFiles from "../../Config/AxiosFiles";

let requestInterceptor, responseInterceptor, refreshingTokenPromise;

const RqState = (props) => {
  const { addToast } = useToasts();

  const initialState = {
    state: false,
    data: null,
    message: null,
  };

  const { refreshLogin } = useContext(AuthContext);
  const [state, dispatch] = useReducer(RqReducer, initialState);

  if (requestInterceptor === undefined) {
    requestInterceptor = axiosClient.interceptors.request.use(
      (config) => {
        return config;
      },
      (err) => {
        console.log("rq_err", err);
      }
    );
  }

  if (responseInterceptor === undefined) {
    responseInterceptor = axiosClient.interceptors.response.use(
      function (response) {
        console.log("rq_response", response.status);
        return response;
      },
      async (error) => {
        let res = error.response;
        console.log("er_response", res);
        if (res.status == 401) {
          console.log("Refreshing token");
          const originalRequest = error.config;
          originalRequest._retry = true;

          if (refreshingTokenPromise === undefined) {
            refreshingTokenPromise = refreshLogin();
          }

          await refreshingTokenPromise;
          refreshingTokenPromise = undefined;

          const { bearer_token, access_token } = getLoggedUser();
          originalRequest.headers["Authorization"] = "Bearer " + bearer_token;
          originalRequest.headers["access_token"] = access_token;

          return axiosClient(originalRequest);
        }
        return Promise.reject(error);
      }
    );
  }

  // Login user on API
  const requestGET = async (data) => {
    const { url, json, fun, save } = data;
    try {
      getLoggedUser();
      const response = await trackPromise(
        axiosClient.post(url, json, { params: { function: fun } })
      );
      dispatch({ type: GET_REQUEST, payload: response.data });
      save(response.data);
    } catch (error) {
      addToast(error.message, {
        appearance: "error",
        autoDismiss: true,
        placement: "bottom-right",
      });
      dispatch({
        type: REQUEST_FAILED,
      });
    }
  };

  const requestPOST = async (data, message = i18n.t("POST_REQUEST")) => {
    const { url, json, fun, save } = data;
    try {
      getLoggedUser();
      const response = await trackPromise(
        axiosClient.post(url, json, { params: { function: fun } })
      );
      dispatch({ type: POST_REQUEST, payload: response.data });
      save(response.data);
      addToast(message, {
        appearance: "success",
        autoDismiss: true,
        placement: "bottom-right",
      });
    } catch (error) {
      let res = error.response;
      if (res.status == 500) {
        try {
          if (typeof res.data.message === "string") {
            addToast(res.data.message, {
              appearance: "error",
              autoDismiss: true,
              placement: "bottom-right",
            });
          }
        } catch (error) {
          console.log("Cant show the error, here is", res.data);
        }
      }
      dispatch({
        type: REQUEST_FAILED,
      });
    }
  };

  const requestPUT = async (data) => {
    const { url, json, fun, save } = data;
    try {
      getLoggedUser();
      const response = await trackPromise(
        axiosClient.post(url, json, { params: { function: fun } })
      );
      dispatch({ type: PUT_REQUEST, payload: response.data });
      save(response.data);
      addToast(i18n.t("PUT_REQUEST"), {
        appearance: "success",
        autoDismiss: true,
        placement: "bottom-right",
      });
    } catch (error) {
      let res = error.response;
      if (res.status == 500) {
        try {
          if (typeof res.data.message === "string") {
            addToast(res.data.message, {
              appearance: "error",
              autoDismiss: true,
              placement: "bottom-right",
            });
          }
        } catch (error) {
          console.log("Cant show the error, here is", res.data);
        }
      }
      dispatch({
        type: REQUEST_FAILED,
      });
    }
  };

  const requestModel = async (data) => {
    const { url, json, save } = data;
    try {
      const response = await trackPromise(axiosClientFiles.put(url, json));
      dispatch({ type: PUT_REQUEST, payload: response.data });
      save(response.data);
    } catch (error) {
      addToast(error.message, {
        appearance: "error",
        autoDismiss: true,
        placement: "bottom-right",
      });
      dispatch({
        type: REQUEST_FAILED,
      });
    }
  };

  const requestDELETE = async (data) => {
    const { url, json, fun } = data;
    try {
      getLoggedUser();
      const response = await trackPromise(
        axiosClient.post(url, json, { params: { function: fun } })
      );
      dispatch({ type: DELETE_REQUEST, payload: response.data });
      addToast(i18n.t("DELETE_REQUEST"), {
        appearance: "success",
        autoDismiss: true,
        placement: "bottom-right",
      });
    } catch (error) {
      addToast(error.message, {
        appearance: "error",
        autoDismiss: true,
        placement: "bottom-right",
      });
      dispatch({
        type: REQUEST_FAILED,
      });
    }
  };

  // Get data of current user
  const getLoggedUser = () => {
    const bearer_token = localStorage.getItem("bearer_token");
    const access_token = localStorage.getItem("access_token");
    if (bearer_token) {
      authToken(bearer_token, access_token);
    }
    return { bearer_token, access_token };
  };

  return (
    <RqContext.Provider
      value={{
        requestData: state.data,
        requestState: state.state,
        requestMessage: state.message,
        requestGET,
        requestPOST,
        requestPUT,
        requestDELETE,
        requestModel,
      }}
    >
      {props.children}
    </RqContext.Provider>
  );
};

export default RqState;
