import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { applyMiddleware, createStore } from "redux";
import thunk from "redux-thunk";
import App from "./App/index";
import Loader from "./App/layout/Loader";
import config from "./config";
import "./i18n";
import * as serviceWorker from "./serviceWorker";
import reducer from "./Store/reducer";

const store = createStore(reducer, applyMiddleware(thunk));

const app = (
  <Suspense fallback={<Loader />}>
    <Provider store={store}>
      <BrowserRouter basename={config.basename}>
        {/* basename="/datta-able" */}
        <App />
      </BrowserRouter>
    </Provider>
  </Suspense>
);

ReactDOM.render(app, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
