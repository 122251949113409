import {
  GET_REQUEST,
  POST_REQUEST,
  PUT_REQUEST,
  DELETE_REQUEST,
  REQUEST_FAILED,
} from "../../Store/actions";

export default (state, action) => {
  console.log(action);
  switch (action.type) {
    case GET_REQUEST:
      return {
        ...state,
        state: false,
        data: action.payload,
        message: null,
      };

    case POST_REQUEST:
      return {
        ...state,
        state: Math.random(),
        data: action.payload,
        message: null,
      };

    case PUT_REQUEST:
      return {
        ...state,
        state: Math.random(),
        data: action.payload,
        message: null,
      };
    case DELETE_REQUEST:
      return {
        ...state,
        state: Math.random(),
        data: action.payload,
        message: null,
      };
    case REQUEST_FAILED:
      // localStorage.removeItem( "bearer_token" )
      // localStorage.removeItem( "access_token" )
      // localStorage.removeItem( "refresh_token" )
      // localStorage.removeItem( "username" )
      // localStorage.removeItem( "session" )
      // localStorage.removeItem( "profile" )

      return {
        ...state,
        state: false,
        data: action.payload,
        message: null,
      };
    default:
      return state;
  }
};
