export default {
  defaultPath: "/auth/signin",
  basename: "/app",
  layout: "vertical",
  preLayout: null,
  collapseMenu: false, // mini-menu
  layoutType: "menu-light", // menu-dark,
  navIconColor: false,
  headerBackColor: "header-default", // header-default
  navBackColor: "navbar-default", // navbar-default
  navBrandColor: "brand-default", // brand-default
  navBackImage: false,
  rtlLayout: false,
  navFixedLayout: true,
  headerFixedLayout: false,
  boxLayout: false,
  navDropdownIcon: "style1", // style1
  navListIcon: "style1", // style1
  navActiveListColor: "active-default", // active-default
  navListTitleColor: "title-default", // title-default
  navListTitleHide: false,
  configBlock: false,
  layout6Background:
    "linear-gradient(to right, #A445B2 0%, #D41872 52%, #FF0066 100%)", // used only for pre-layout = layout-6
  layout6BackSize: "", // used only for pre-layout = layout-6
};
